import { QuestionType } from "../../core/enums/QuestionTypes";
import { Event } from "../Event";

export class QuestionRegistrationItemTypeEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if (Number(value) === QuestionType.FREE_FIELD) {
            this.setFieldValue("question_registration_item_not_applicable", false);

            this.setField("question_registration_item_not_applicable", {
                readonly: true
            });
        } else {
            this.setField("question_registration_item_not_applicable", {
                readonly: false
            });
        }
    }
}