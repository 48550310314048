import React from 'react';
import { Button, Input, Modal, Notification } from 'rsuite';
import "./styles/sytleResetPassword.css"
import { MethodsUtils } from '../../../../core/utils/MethodsUtils';
import { trim, trimStart } from 'lodash';
import axios from '../../../../utilities/axios';
import { ResponseDefault } from '../../../../core/interfaces/interfaces';
import { duration } from 'moment';

type ResetPass = {
    userId : number
    userCpf : string,
    justification: string;
    solicitedBy: string
}

type State = {
    viewModalJustification: boolean;
    justification: string;
    dataInfo: any;
    data: any; 
    isResetting: boolean;
};


export default class ResetPasswordUser extends React.Component<any, State> {

    state: State = {
        viewModalJustification: false,
        justification: "",
        dataInfo:null,
        data: this.props.values,
        isResetting: false,
    };

    async componentDidMount(): Promise<void> {
         try {
           const response = await axios.get('/api/v1/me');
            const dataInfo = response.data.user;
            this.setState({ dataInfo });
            
        } catch (error) {
            console.error("Erro ao buscar dados:", error); 
        }
    }

    handleClickReset = () => {
        this.setState({viewModalJustification : true});
    }

    closeModalReset = () => {
        this.setState({
            justification: "",
            viewModalJustification: false,
            isResetting: false,
        });
    }

    resetPassword = async (loggedID: number, loggedName: string, loggedRole: string, loggedRoleId: number) => {
        this.setState({ isResetting: true });
        try {        
            const data = this.props.values;
            const restorePass: ResetPass = {
                userId: data.user_id,
                userCpf: data.user_cpf_cnpj,
                justification: this.state.justification,
                solicitedBy: `ID: ${loggedID} - Nome: ${loggedName} - Tipo de Regra: ${loggedRole} - RoleID: ${loggedRoleId}`
            }

            const sendReq: ResponseDefault = await axios.post('/api/v1/user/reset', {restorePass}).then((res) => {return res.data});
            console.log({sendReq})

            if (sendReq.status === 200) {
                this.closeModalReset();
                Notification.success({
                    title: "Sucesso",
                    description: sendReq.message,
                    duration: 5000
                })
            
            } else {
                Notification.error({
                    title: "Sucesso",
                    description: sendReq.message,
                    duration: 5000
                })
            }
        } catch (e) {
            console.error("Erro ao redefinir senha:", e);
                Notification.error({
                    title: "Erro",
                    description: "Ocorreu um erro ao redefinir a senha.",
                    duration: 5000,
                });
        
        } finally {
            this.setState({ isResetting: false }); 
        }

    }
    
    render() {
        const { viewModalJustification, justification, dataInfo, data } = this.state;
        return (
            <>
                <div className="containner-button">
                    <Button
                        appearance='primary'
                        color='violet'
                        onClick={this.handleClickReset}
                        >Redefinir Senha
                    </Button>
                </div>

                <Modal
                    className='containner-modal'
                    show={this.state.viewModalJustification}
                    size='lg'
                    onHide={this.closeModalReset}
                    backdrop="static"
                    onExit={this.closeModalReset}
                    keyboard={true}>
                    
                    <Modal.Header>
                        <Modal.Title>REDEFINIR SENHA</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {data.user_cpf_cnpj && (
                            <>
                                <strong>CPF:</strong> {MethodsUtils.FormatCpfCnpj(data.user_cpf_cnpj)} - <strong>Nome:</strong> {data.user_name}
                            </>
                        )}
                        <p>
                            <div className='margin-10'>
                                <span><strong>JUSTIFIQUE O MOTIVO PARA A REDEFINIÇÃO</strong></span>
                            </div>
                        </p>

                        <div className='margin-10'>
                            <Input
                                id="justification"
                                className='justification'
                                componentClass="textarea"
                                rows={3}
                                placeholder="Justificativa obrigatória"
                                value={this.state.justification}
                                onChange={(e) => {
                                    const value = e.slice(0, 200)
                                    this.setState({ justification: trimStart(value) })
                                }}
                            />
                            <span className='counter'>{this.state.justification.length}/200</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button color='red'
                            onClick={this.closeModalReset}>Fechar
                        </Button>

                        <Button color='cyan'
                            disabled={this.state.justification.length <= 10 || this.state.isResetting}
                            onClick={() =>
                                this.resetPassword(
                                    dataInfo.user_id,
                                    dataInfo.user_name,
                                    dataInfo.role.role_name,
                                    dataInfo.role.role_id)}
                        > {this.state.isResetting ? (
                            <>
                                <span
                                    className="rs-icon rs-icon-spinner rs-icon-spin"
                                    style={{ marginRight: 8 }} />Processando...
                            </>
                        ) : (
                            "Redefinir"
                        )}
                        </Button>
                    </Modal.Footer>

                </Modal>
             </>
        )
    }
}