import moment from 'moment';
import { FormatedPlate } from '../interfaces/interfaces';
import dayjs from 'dayjs';
import * as os from 'os';

export class MethodsUtils {

 static ConvertToDate(dateConvert: Date | string, defaultBrasil?: boolean): Date | string {
        let parseDate = moment(dateConvert, ['DD/MM/YYYY', 'YYYY-MM-DD']);

        if (!parseDate.isValid()) {
            throw new Error('Data inválida');
        }

        if (defaultBrasil) {
            return parseDate.format('DD/MM/YYYY');
        }
        return parseDate.format('YYYY-MM-DD');
    }

    static FormatCpfCnpj(input: string): string | null {
        const sanitizedInput = input.replace(/\D/g, "");

        if (/^\d{11}$/.test(sanitizedInput)) {
            return sanitizedInput.replace(
                /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
                "$1.$2.$3-$4"
            );
        }
    
        if (/^\d{14}$/.test(sanitizedInput)) {
            return sanitizedInput.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
                "$1.$2.$3/$4-$5"
            );
        }
        return null;
    }

    static async convertTimeToMilliseconds(timeInput: Date | string, includeSeconds: boolean = true): Promise<number> {
        let time;

        if (timeInput instanceof Date) {
            time = moment(timeInput).startOf('day');
        } else {


            if (timeInput.length === 4 && !timeInput.includes(':')) {
                timeInput = timeInput.slice(0, 2) + ':' + timeInput.slice(2);
            }

            
            const [hoursStr, minutesStr, secondsStr] = timeInput.split(':');
            const hours = parseInt(hoursStr, 10);
            const minutes = parseInt(minutesStr, 10);
            const seconds = parseInt(secondsStr || '0', 10); 

            return (hours * 3600000) + (minutes * 60000) + (includeSeconds ? seconds * 1000 : 0);
            
        }

        const milliseconds = time.hours() * 3600000 + time.minutes() * 60000 + (includeSeconds ? time.seconds() * 1000 : 0);

        return milliseconds;
    }

    
    static async convertMillisecondsToTime(milliseconds: number, includeSeconds: boolean): Promise<string> {
        const hours = Math.floor(milliseconds / 3600000);
        const minutes = Math.floor((milliseconds % 3600000) / 60000);
        const seconds = Math.floor((milliseconds % 60000) / 1000);

        if (includeSeconds) 
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
         else 
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }    



    static async diffHour(hourEnd: string, hourStart: string): Promise<number> {
        const hourFinally: number = await this.convertTimeToMilliseconds(hourEnd);
        const hourInit: number = await this.convertTimeToMilliseconds(hourStart);
        const result: number = hourFinally - hourInit;
        
        return result < 0 ? 0 : result;
    }

    static async intervalCalculated(hourEnd: string, hourStart: string, interval: string): Promise<string> {
        const diff: number = await this.diffHour(hourEnd, hourStart)
        const convInterval: number = await this.convertTimeToMilliseconds(interval, false);
        const result: number = diff - convInterval;

        return result > 0 ? await this.convertMillisecondsToTime(result, false) : await this.convertMillisecondsToTime(0, false);

    }

    static async isValidHour(timeString: string): Promise<boolean> {
        const timePattern = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;

        if (!timePattern.test(timeString)) {
            return false;
        }
        
        const [hours, minutes] = timeString.split(':').map(Number);
        return (hours >= 0 && hours <= 23) && (minutes >= 0 && minutes <= 59);

    }

    // Indicar para o método a string que será tratada, o caracter que será removido, e o caracter que será colocado no lugar do removido
    // exemplo this.removeCharString("00:00", ":", "")  => RESULTADO "0000"
    static removeCharString(value: string, charRemove: string, charSub: string): string {
        return value.replace(charRemove, charSub);
    }

    // Formatar a Placa no Padrão Antigo BR - ABC-1234 ou Mercosul DYB-5G49
    static async formatAndValidatePlate(plate: string): Promise<FormatedPlate> {
        const regex = /^[A-Za-z]{3}\d{4}$|^[A-Za-z]{3}\d[A-Za-z]\d{2}$/;
        plate = plate.replace(/-/g, '');
        if (!regex.test(plate)) {
        return { valid: false, message: `Placa inválida: ${plate}. Use o formato ABC-1234 (Padrão Antigo BR) ou ABC-0A00 (Padrão Mercosul).` };
    }

    let formattedPlate;
        if (plate.length === 7) {
                formattedPlate = plate.slice(0, 3) + '-' + plate.slice(3);
        } else if (plate.length === 8) {
            formattedPlate = plate.slice(0, 4) + '-' + plate.slice(4);
        }
        return { valid: true, formatted: formattedPlate };
    }

    static FormatPlate(plate: string): string {
        let formattedPlate = "";
        
        if (plate.length === 7) {
            formattedPlate = plate.slice(0, 3) + '-' + plate.slice(3);
        } else if (plate.length === 8) {
            formattedPlate = plate.slice(0, 4) + '-' + plate.slice(4);
         }
         return formattedPlate 

    }

    static FormatPlateAlter = (value: string) => {

        if (!value) {
            return ""
        }
        // Remove caracteres que não sejam letras ou números
        let cleanValue = value.replace(/[^A-Za-z0-9]/g, '');

        // Verifica os segmentos para a placa de carro
        let letters = cleanValue.substring(0, 3).replace(/[^A-Za-z]/g, '');
        let firstNumber = cleanValue.substring(3, 4).replace(/[^0-9]/g, '');
        let letterOrNumber = cleanValue.substring(4, 5).replace(/[^A-Za-z0-9]/g, '');
        let lastNumbers = cleanValue.substring(5, 7).replace(/[^0-9]/g, '');

        // Cria o valor formatado sem adicionar o hífen permanentemente
        let formattedValue = `${letters}${firstNumber}${letterOrNumber}${lastNumbers}`;

        // Exibe o hífen na visualização, mas permite apagar sem interferência
        if (formattedValue.length > 3) {
            formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(3)}`;
        }

        // Limita o tamanho da string formatada a 8 caracteres
        return formattedValue.toUpperCase().substring(0, 8);
    };

    static async UnformatedPlate(plate: string): Promise<string> {
        let formattedPlate = "";
        formattedPlate = plate.replace('-',"")
        return formattedPlate.toUpperCase();
    }

    static formatName(fullName: string): string {
      const names = fullName.trim().split(/\s+/);
  
        if (names.length === 1) {
            return names[0];
        }
        
            return `${names[0]} ${names[names.length - 1]}`;
    }

    static async formatDocument(document: string): Promise<string> {
        document = document.replace(/\D/g, '');

        if (document.length > 11) {
            document = document.slice(0, 14);
            if (document.length <= 2) {
                return document;
            } else if (document.length <= 5) {
                return document.replace(/(\d{2})(\d{0,3})/, '$1.$2');
            } else if (document.length <= 8) {
                return document.replace(/(\d{2})(\d{3})(\d{0,3})/, '$1.$2.$3');
            } else if (document.length <= 12) {
                return document.replace(/(\d{2})(\d{3})(\d{3})(\d{0,4})/, '$1.$2.$3/$4');
            } else {
                return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})/, '$1.$2.$3/$4-$5');
            }
        }

        document = document.slice(0, 11);
        if (document.length <= 3) {
            return document;
        } else if (document.length <= 6) {
            return document.replace(/(\d{3})(\d{0,3})/, '$1.$2');
        } else if (document.length <= 9) {
            return document.replace(/(\d{3})(\d{3})(\d{0,3})/, '$1.$2.$3');
        } else {
            return document.replace(/(\d{3})(\d{3})(\d{3})(\d{0,2})/, '$1.$2.$3-$4');
        }
    }

    static async formatRG(RG: string): Promise<string> {
        RG = RG.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
        RG = RG.slice(0, 9); // Limita no maximo 9 caracteres

        if (RG.length <= 2) {
            return RG;
        } else if (RG.length <= 5) {
            // formato: XX.XXX
            return RG.replace(/([a-zA-Z0-9]{2})([a-zA-Z0-9]{0,3})/, '$1.$2');
        } else if (RG.length <= 8) {
            // formato: XX.XXX.XXX
            return RG.replace(/([a-zA-Z0-9]{2})([a-zA-Z0-9]{3})([a-zA-Z0-9]{0,3})/, '$1.$2.$3');
        } else {
            // formato: XX.XXX.XXX-X
            return RG.replace(/([a-zA-Z0-9]{2})([a-zA-Z0-9]{3})([a-zA-Z0-9]{3})([a-zA-Z0-9]{0,1})/, '$1.$2.$3-$4');
        }
    }
    
    static async generateDateHourGMTBrasilDF(): Promise<string> {
        return dayjs(new Date()).subtract(3, 'hour').toString();

    }

    static async generateDateHour(): Promise<string> {
        return dayjs(new Date()).toString();

    }

    static extractNumberFromString(input: string): number {
        const numberString = input.replace(/\D/g, ''); 
        return parseInt(numberString, 10); 
    }

}