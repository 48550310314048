import { ArrowLeftLine } from "@rsuite/icons";
import axios from "axios";
import { destroyCookie, parseCookies } from "nookies";
import React from "react";
import { Button, ButtonGroup, Icon, Input, Modal, Notification, Panel } from "rsuite";
import { WYSIWYG } from "../../../../components/formfields/WYSIWYG";
import ModalOverlay from "../../../../components/modalOverlay/ModalOverlay";
import { QuestionType } from "../../../../core/enums/QuestionTypes";
import { IGeolocation } from "../../../../core/interfaces/interfaces";
import { UseGeolocation } from "../../../../core/utils/findGeolocation";
import { MethodsUtils } from "../../../../core/utils/MethodsUtils";
import SignaturePad from "./signature";
import styles from "./styles.module.scss";

let sendingAttempts: number = 0;

const cookies = parseCookies();

type Question = {
    questionNumber: number;
    questionID: number;
    question: string;
    questionAnswer: string;
    questionType: number;
    questionNA: boolean;
}

type QuestionItem = {
    questionID: number;
    question: string;
    answer: string;
    questionType: number;
    questionNA: boolean;
}

interface AnswerQuestionsState {
    totalQuestions: number,
    step: number;
    hiddenReproveMotivation: boolean;
    isReproving: boolean;
    reprovingText: string;
    lastQuestionAnswered: boolean;
    questions: Question[];
    answers: any;
    checklistInformations: any;
    activeCamera: boolean;
    hasPicture: boolean;
    showActiveCamera: boolean;
    signatureImg: string;
    finishModalVisible: boolean;
    signatureModalIsOpen: boolean;
    modalSending: boolean,
    modalSendingMessage: string,
    finishSendingModalVisible: boolean,
    lastAnsweredQuestionNumber: number,
    currentFreeFieldValue: string;
    finishName: string;
    finishRG: string;
}

class AnswerQuestions extends React.Component<any, AnswerQuestionsState> {
    videoRef = React.createRef<HTMLVideoElement>();
    canvasRef = React.createRef<HTMLCanvasElement>();
    stream: MediaStream | null = null;
        
    constructor(props: any) {
        super(props);
        
        this.state = {
            totalQuestions: 0,
            step: 0,
            lastAnsweredQuestionNumber: 0, 
            hiddenReproveMotivation: true,
            isReproving: false,
            reprovingText: "",
            lastQuestionAnswered: false,
            questions: [],
            answers: [],
            checklistInformations: null,
            activeCamera: false,
            hasPicture: false,
            showActiveCamera: true,
            signatureImg: "",
            finishModalVisible: false,
            signatureModalIsOpen: false,
            modalSending: false,
            modalSendingMessage: "",
            finishSendingModalVisible: false,
            currentFreeFieldValue: "",
            finishName: "",
            finishRG: "",
        };
    }

async componentDidMount(): Promise<void> {
        this.setState({
            modalSending: true, 
            modalSendingMessage: "Carregando Questionário"})
        
        const checklistInformations = cookies["@checklistInformations"];

        if (!checklistInformations) {
            window.location.href = '/checklist';
            return;
        } 

        let checklistInformationsParse = JSON.parse(checklistInformations);
        
        // Irá deixar em branco os dados veículo, para evitar utilizar os dados do último, caso a flag de placa obrigatória tenha sido modificada
        if (!checklistInformationsParse.requestPlate) {
            checklistInformationsParse.vehicle.plate = '';
            checklistInformationsParse.vehicle.mark = '';
            checklistInformationsParse.vehicle.model = '';
        }
        
        this.setState({ checklistInformations: checklistInformationsParse });
        
        const questionsResponse = await axios.get("/checklist/getquestions/" + checklistInformationsParse?.checklistTableId)
            .then(response => {
                const questions: Question[] = response.data.questions.map((item: QuestionItem, index: number) => ({
                    questionNumber: index + 1,
                    questionID: item.questionID,
                    question: item.question,
                    questionAnswer: item.answer,
                    questionType: item.questionType,
                    questionNA: item.questionNA
                }));
                this.setState({ questions: questions})
                return questions;
            })

        .catch(error => {
            console.error('Erro ao buscar perguntas:', error);
            return []; // Retorna uma lista vazia em caso de erro
        });
        
        this.setState({
            totalQuestions: questionsResponse.length,
            modalSending: false, 
            modalSendingMessage: ""});
    }

    componentDidUpdate(prevProps: any, prevState: AnswerQuestionsState) {
        const currentQuestion = this.state.questions[this.state.step];
        if (currentQuestion?.questionType === QuestionType.PHOTO && !this.state.activeCamera && !this.state.hasPicture) {
            this.startCamera();
        }
    }

    handleChangeSignatureModal = () => {
        this.setState({
            signatureModalIsOpen: !this.state.signatureModalIsOpen
        })
    }

    onReturn = (): void => {
        if (this.state.hasPicture) {
            this.setState({
                hasPicture: false,
                activeCamera: true,
                showActiveCamera: true,
            });

            this.startCamera()
        } else if (this.state.isReproving) {
            this.setState({
                isReproving: false,
                hiddenReproveMotivation: true,
            });
        } else {
            this.stopCamera();
            this.setState({ lastQuestionAnswered: false });
            this.onChangeSteps(this.state.step - 1);
        }
    };

    onChangeSteps(nextStep: number): void {
        
        if (nextStep === this.state.totalQuestions) {
                this.setState({ lastQuestionAnswered: true });
        } else {
            let newStep = nextStep;

            if (nextStep < 0) {
                newStep = 0;
            } else if (nextStep >= this.state.totalQuestions) {
                newStep = this.state.totalQuestions - 1;  
            }

            const currentQuestion = this.state.questions[newStep];

            if (newStep > this.state.lastAnsweredQuestionNumber) {
                this.setState({ step: newStep, lastAnsweredQuestionNumber: newStep }, () => {
                    if (currentQuestion) {
                        this.updateLastAnsweredQuestionNumber(currentQuestion.questionNumber);
                    }
                });
            } else {
                
                this.setState({ step: newStep });
            }
        }
    }
    
    onAdvanceSteps = (): void => {
        const { step, totalQuestions, lastAnsweredQuestionNumber } = this.state;
        let nextStep = step + 1;

        if (nextStep > lastAnsweredQuestionNumber) {
            Notification.warning({
                title: "Aviso",
                description: "Você não pode avançar além da última pergunta respondida.",
                placement: "bottomEnd"
            });
            return;
        }

        if (nextStep <= totalQuestions) {
            this.onChangeSteps(nextStep);
        }
    };


    onAprove = (): void => {
        const currentQuestion = this.state.questions[this.state.step];

        if (this.state.questions[this.state.step + 1] && 
            this.state.questions[this.state.step + 1].questionType === QuestionType.PHOTO) {
            this.startCamera();
        }

        this.setState(prevState => {
            const existingAnswerIndex = prevState.answers.findIndex(
                (ans: any) => ans.questionNumber === currentQuestion.questionNumber
            );

            let updatedAnswers;

            if (existingAnswerIndex !== -1) {
                updatedAnswers = [...prevState.answers];
                updatedAnswers[existingAnswerIndex] = {
                    ...updatedAnswers[existingAnswerIndex],
                    question: currentQuestion.question,
                    questionAnswer: true,
                    questionType: currentQuestion.questionType,
                    questionNA: currentQuestion.questionNA,
                };
            } else {
                updatedAnswers = [
                    ...prevState.answers,
                    {
                        questionNumber: currentQuestion.questionNumber,
                        question: currentQuestion.question,
                        questionAnswer: true,
                        questionType: currentQuestion.questionType,
                        questionNA: currentQuestion.questionNA,
                    }
                ];
            }

            const isNewQuestion = currentQuestion.questionNumber > prevState.lastAnsweredQuestionNumber;

            return { answers: updatedAnswers, 
                lastAnsweredQuestionNumber: isNewQuestion ? currentQuestion.questionNumber : prevState.lastAnsweredQuestionNumber
                };
        });

        // Avança para a próxima etapa
        this.onChangeSteps(this.state.step + 1);
    };
    
    notApplicable = (): void => {
        const currentQuestion = this.state.questions[this.state.step];

        this.setState(prevState => {
            // console.log("Antes da alteração NA:", prevState.answers);

            const existingAnswerIndex = prevState.answers.findIndex(
                (ans: any) => ans.questionNumber === currentQuestion.questionNumber
            );

            let updatedAnswers;

            if (existingAnswerIndex !== -1) {
                updatedAnswers = [...prevState.answers];
                updatedAnswers[existingAnswerIndex] = {
                    ...updatedAnswers[existingAnswerIndex],
                    question: currentQuestion.question,
                    questionAnswer: false,
                    questionType: currentQuestion.questionType,
                    reason: this.state.reprovingText,
                    questionNA: currentQuestion.questionNA
                };
            } else {
                updatedAnswers = [
                    ...prevState.answers,
                    {
                        questionNumber: currentQuestion.questionNumber,
                        question: currentQuestion.question,
                        questionAnswer: false,
                        questionType: currentQuestion.questionType,
                        reason: this.state.reprovingText,
                        questionNA: currentQuestion.questionNA
                    }
                ];
            }

            // Log do estado atualizado de answers
            // console.log("Depois da alteração NA:", updatedAnswers);

             const isNewQuestion = currentQuestion.questionNumber > prevState.lastAnsweredQuestionNumber;
            
            return {
                answers: updatedAnswers,
                hiddenReproveMotivation: true,
                isReproving: false,
                reprovingText: "",
                lastAnsweredQuestionNumber: isNewQuestion ? currentQuestion.questionNumber : prevState.lastAnsweredQuestionNumber
            };
        }, () => {
            this.forceUpdate();
        });

        // Avança para a próxima etapa
        this.onChangeSteps(this.state.step + 1);
    };

    onReprove = (): void => {
        const currentQuestion = this.state.questions[this.state.step];

        if (this.state.isReproving && this.state.reprovingText) {
            this.setState(prevState => {
                const existingAnswerIndex = prevState.answers.findIndex(
                    (ans: any) => ans.questionNumber === currentQuestion.questionNumber
                );

                let updatedAnswers;

                if (existingAnswerIndex !== -1) {
                    // Se já existe, altera a resposta existente
                    updatedAnswers = [...prevState.answers];
                    updatedAnswers[existingAnswerIndex] = {
                        ...updatedAnswers[existingAnswerIndex],
                        question: currentQuestion.question,
                        questionAnswer: false,
                        questionType: currentQuestion.questionType,
                        reason: this.state.reprovingText,
                        questionNA: currentQuestion.questionNA
                    };
                } else {
                    // Se não existe, adiciona a nova resposta
                    updatedAnswers = [
                        ...prevState.answers,
                        {
                            questionNumber: currentQuestion.questionNumber,
                            question: currentQuestion.question,
                            questionAnswer: false,
                            questionType: currentQuestion.questionType,
                            reason: this.state.reprovingText,
                            questionNA: currentQuestion.questionNA
                        }
                    ];
                }

                // Log do estado atualizado de answers
                // console.log("Depois da alteração REPROVA:", updatedAnswers);

                 const isNewQuestion = currentQuestion.questionNumber > prevState.lastAnsweredQuestionNumber;
            
                return {
                    answers: updatedAnswers,
                    hiddenReproveMotivation: true,
                    isReproving: false,
                    reprovingText: "",
                    lastAnsweredQuestionNumber: isNewQuestion ? currentQuestion.questionNumber : prevState.lastAnsweredQuestionNumber
                };
            }, () => {
                this.forceUpdate();
            });

            // Avança para a próxima etapa
            this.onChangeSteps(this.state.step + 1);
        } else if (!this.state.isReproving) {
            // Exibe o motivo de reprovação
            this.setState({ hiddenReproveMotivation: false, isReproving: true });
        } else {
            // Notifica caso o motivo de reprovação não tenha sido preenchido
            Notification.warning({
                title: "Aviso",
                description: "Preencha o motivo de reprovação para reprovar.",
                placement: "bottomEnd"
            });
        }
    };

    updateLastAnsweredQuestionNumber = (questionNumber: number): void => {
      this.setState({ lastAnsweredQuestionNumber: questionNumber });
    };

    handleFinish = async (): Promise<void> => {
        if (!this.state.finishName.trim() || !this.state.finishRG.trim()) {
            Notification.error({
                title: "Erro!",
                description: "Nome e RG são campos obrigatórios.",
                placement: "bottomEnd"
            });
            return;
        }

        this.setState({
            modalSending: true,
            modalSendingMessage: "Registrando Informações...",
        });       

        sendingAttempts++;
        this.stopCamera();
        this.setState({
            finishModalVisible: false
        })

        const { answers, checklistInformations, signatureImg } = this.state;

        if (!signatureImg) {
            Notification.error({
                title: "Erro!",
                description: "É necessário assinar o documento antes de finalizar o checklist.",
                placement: "bottomEnd"
            })

            return;
        }
        
        const geoInit: IGeolocation = JSON.parse(cookies["@geolocation"]);
        const geoFinish: IGeolocation = await UseGeolocation.findGeolocation();

        const newObj = { 
                ...checklistInformations, 
                answers: { ...answers }, 
                checklistStart: cookies["@startChecklist"],
                checklistFinish: await MethodsUtils.generateDateHour(),
                geoInit: geoInit,
                geoFinish: geoFinish,
                signature: signatureImg,
                sendingAttempts: sendingAttempts,
                finishName: this.state.finishName,
                finishRG: this.state.finishRG
             };

        try {
       
            this.setState({
                    modalSendingMessage: "Enviando checklist...",
            });         

            if (newObj.geoInit !== "" && newObj.geoFinish !== "") {

                // console.log({newObj});

                const sendToApi = await axios.post("/checklist/addnew", {newObj})
                
                if (sendToApi.status === 200 || sendToApi.status === 201) {
                        destroyCookie(null, '@checklistInformations', {path: '/',});
                        destroyCookie(null, '@geolocation', {path: '/',});
                        destroyCookie(null, '@startChecklist', {path: '/',});
                    
                        let countdown = 5;
                        const interval = setInterval(() => {
                            countdown -= 1;
                            if (countdown > 0) {
                                this.setState({
                                    modalSendingMessage: `Checklist enviado com sucesso! Redirecionando em ${countdown} segundos...`,
                                });
                            } else {
                                clearInterval(interval); 
                                this.setState({ modalSending: false });
                                window.location.href = "/checklist/list";
                            }
                        }, 1000);
                
                } else {
                    sendingAttempts++;
                    Notification.error({ 
                        title: "Erro", 
                        description: "Não foi possível enviar o checklist! Tente novamente!", 
                        placement: "bottomEnd" });
                }
            } else {
                        let countdown = 5;
                        const interval = setInterval(() => {
                            countdown -= 1;
                            if (countdown > 0) {
                                this.setState({
                                    modalSendingMessage: `Erro ao Enviar o Checklist! aguarde ${countdown} segundos...`,
                                });
                            } else {
                                clearInterval(interval); 
                                this.setState({ modalSending: false });
                            }
                        }, 1000);
            }
            
        } catch (error) {
                sendingAttempts++;
                Notification.error({ 
                    title: "Erro", 
                    description: "Erro ao enviar o checklist.", 
                    placement: "bottomEnd" });
            }
    };
   
    handleConfirmImage = async () => {
        await this.stopCamera();
        const { answers, step, questions } = this.state;
        const question = questions[step].question;

        const imageData = this.canvasRef.current?.toDataURL('image/jpeg');

        this.setState({
            answers: [
                ...answers.filter((answer: any) => answer.question !== question),
                { questionNumber: questions[step].questionNumber-1,  question, image: imageData, questionType: questions[step].questionType } 
            ],
            hasPicture: false,
            activeCamera: false,
            showActiveCamera: true
        }, () => this.onChangeSteps(step + 1));
    };

    handleSetSignature = (img: string) => {
        this.setState({ signatureImg: img });

        this.setState({
            finishModalVisible: true
        })
    };


    startCamera = async () => {
        this.setState({
            activeCamera: true,
            hasPicture: false
        }, ()=> {this.forceUpdate()})

        try {
            this.stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
            if (this.videoRef.current) {
                this.videoRef.current.srcObject = this.stream;
            }
        } catch (error) {
            console.error("Erro ao acessar a câmera:", error);
            Notification.error({
                title: "Erro",
                description: "Não foi possível acessar a câmera.",
                placement: "bottomEnd"
            });
        }
    }

    stopCamera = async () => {
        this.setState({
            activeCamera: false
        }, () => {this.forceUpdate()});
        
        if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
            this.stream = null;
            if (this.videoRef.current) {
                this.videoRef.current.srcObject = null;
            }
        }
    }

    captureImage = () => {
        if (!this.videoRef.current || !this.canvasRef.current) {
            Notification.error({
                title: "Erro",
                description: "Erro ao capturar imagem. Verifique se a câmera está ativa.",
                placement: "bottomEnd"
            });
            return;
        }

        const video = this.videoRef.current;
        const canvas = this.canvasRef.current;
        const context = canvas.getContext('2d');

        if (!context || !video.videoWidth || !video.videoHeight) {
            Notification.error({
                title: "Erro",
                description: "Aguarde a câmera inicializar completamente antes de capturar.",
                placement: "bottomEnd"
            });
            return;
        }

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        try {
            const imageData = canvas.toDataURL('image/jpeg');
            // console.log('Imagem capturada:', imageData);

            this.setState({
                hasPicture: true,
                activeCamera: false,
                showActiveCamera: false
            }, ()=> {this.forceUpdate()});

            this.stopCamera();

            Notification.success({
                title: "Sucesso",
                description: "Imagem capturada com sucesso!",
                placement: "bottomEnd"
            });
        } catch (error) {
            console.error('Erro ao capturar imagem:', error);
            Notification.error({
                title: "Erro",
                description: "Erro ao processar a imagem capturada.",
                placement: "bottomEnd"
            });
        }
    }

    retakeImage = () => {
        // Reinicia o estado e reativa a câmera
        this.setState({ hasPicture: false }, this.startCamera);
    }

    handleReturnRegisterChecklists(): void {
        window.location.href = '/checklist/register';
    }

    isImageFilled = () => {
        const currentAnswer = this.state.answers[this.state.step];
        return currentAnswer && currentAnswer.image ? true : false;
    }

    onChangeFreeFieldInput = (value: string): void => {
        const cleanValue = value.replace(/<[^>]*>/g, '').trim();
        
        this.setState({
            currentFreeFieldValue: cleanValue
        });

        const currentQuestion = this.state.questions[this.state.step];

        this.setState(prevState => {
            const existingAnswerIndex = prevState.answers.findIndex(
                (ans: any) => ans.questionNumber === currentQuestion.questionNumber
            );

            let updatedAnswers;

            if (existingAnswerIndex !== -1) {
                updatedAnswers = [...prevState.answers];
                updatedAnswers[existingAnswerIndex] = {
                    ...updatedAnswers[existingAnswerIndex],
                    question: currentQuestion.question,
                    questionAnswer: value,
                    questionType: currentQuestion.questionType,
                    questionNA: currentQuestion.questionNA
                };
            } else {
                updatedAnswers = [
                    ...prevState.answers,
                    {
                        questionNumber: currentQuestion.questionNumber,
                        question: currentQuestion.question,
                        questionAnswer: value,
                        questionType: currentQuestion.questionType,
                        questionNA: currentQuestion.questionNA
                    }
                ];
            }

            const isNewQuestion = currentQuestion.questionNumber > prevState.lastAnsweredQuestionNumber;

            return {
                answers: updatedAnswers,
                lastAnsweredQuestionNumber: isNewQuestion ? currentQuestion.questionNumber : prevState.lastAnsweredQuestionNumber
            };
        });
    };

    render() {
        const currentQuestion = this.state.questions[this.state.step];
        const currentAnswer = this.state.answers.find(
            (ans: any) => ans.questionNumber === currentQuestion?.questionNumber
        );

        return (
            <>
                <div className={styles.header}>
                    <div className={styles.headerContainer} onClick={this.handleReturnRegisterChecklists}>
                        <ArrowLeftLine className={styles.arrow} onClick={this.handleReturnRegisterChecklists} />
                        <span className={styles.returnPageText}>Voltar ao Início</span>
                    </div>
                    <h1 className={styles.userName}>Perguntas</h1>
                </div>
                <div className={styles.container}>
                    <div className={styles.mainContainer}>
                        <div>
                            {this.state.questions.length > 0 && (
                                <Panel header={`Pergunta: ${this.state.step + 1}/${this.state.questions.length}`} key={this.state.step}>
                                    {this.state.questions[this.state.step]?.question}
                                </Panel>
                            )}
                            <hr />
                            
                                {this.state.questions[this.state.step]?.questionType === QuestionType.TEXT && (
                                    <>
                                        <ButtonGroup className={styles.buttons} vertical={true}>
                                        {this.state.step !== 0 && !this.state.isReproving && (
                                        <Button onClick={this.onReturn} 
                                                disabled={this.state.step === 0 && !this.state.isReproving} 
                                                appearance="default" 
                                                color="yellow">
                                            Voltar
                                        </Button>
                                        )}
                                            <Button 
                                                    onClick={this.onReprove} 
                                                    appearance="default" 
                                                    color="red">
                                                {this.state.isReproving ? (<span>Confirmar Reprovado</span>) : (<span>Reprovado</span>)}
                                            
                                            </Button>
                                            {!this.state.isReproving && (
                                                <Button onClick={this.onAprove} 
                                                    disabled={this.state.step >= this.state.questions.length} 
                                                    appearance="default" 
                                                    color="green">
                                                    Aprovado
                                                </Button>
                                            )}
                                            
                                            {this.state.questions[this.state.step]?.questionNA && this.state.hiddenReproveMotivation && (
                                                <Button 
                                                        onClick={this.notApplicable} 
                                                        disabled={this.state.step >= this.state.questions.length} 
                                                        appearance="default" 
                                                        color="orange"> NÃO SE APLICA (N/A)
                                                </Button>
                                            )}

                                            {this.state.step < this.state.lastAnsweredQuestionNumber-1 && (
                                            <Button onClick={this.onAdvanceSteps} 
                                                    appearance="primary" 
                                                    color="blue">
                                                Avançar
                                            </Button>
                                        )}
                                        </ButtonGroup>
                                    </>
                                )
                            }

                            {(this.state.questions[this.state.step]?.questionType === QuestionType.PHOTO) && 
                                <>
                                    <div className={styles.pictureButtons}>
                                        {/* {this.startCamera()} */}
                                        <Button 
                                            onClick={this.onReturn} 
                                            disabled={this.state.step === 0 && !this.state.isReproving} 
                                            className={styles.returnButton} 
                                            appearance="primary" 
                                            color="yellow">Voltar
                                        </Button>
                                        
                                        {this.state.step <= this.state.lastAnsweredQuestionNumber - 1 && this.isImageFilled() && (
                                            <Button onClick={this.onAdvanceSteps} 
                                                    appearance="primary" 
                                                    color="blue">
                                                Avançar
                                            </Button>
                                        )}

                                        <Button 
                                            onClick={this.captureImage} 
                                            appearance="primary" 
                                            color="violet" 
                                            disabled={!this.state.activeCamera} 
                                            className={styles.cameraButton}>
                                                <Icon icon='camera-retro' size="lg" />
                                        </Button>
                                        {this.state.step < this.state.lastAnsweredQuestionNumber-1 && (
                                            <Button onClick={this.onAdvanceSteps} 
                                                    appearance="primary" 
                                                    color="blue">
                                                Avançar
                                            </Button>
                                        )}
                                        {this.state.questions[this.state.step].questionNA && (
                                            <Button className={styles.notapplic}
                                                    onClick={this.notApplicable} 
                                                    disabled={this.state.step >= this.state.questions.length} 
                                                    appearance="primary" color="orange"> NÃO SE APLICA (N/A)
                                            </Button>
                                        )}

                                        {this.state.hasPicture && (
                                            <>
                                                <Button 
                                                    onClick={this.retakeImage} 
                                                    appearance="primary" 
                                                    color="orange" 
                                                    className={styles.cameraButton}>Tirar Novamente
                                                </Button>
                                                
                                                <Button 
                                                    onClick={this.handleConfirmImage} 
                                                    appearance="primary" 
                                                    color="green" 
                                                    className={styles.cameraButton}>
                                                        Confirmar
                                                </Button>
                                            </>
                                        )} 
                                    </div>

                                    {this.state.activeCamera && (
                                        <video
                                            ref={this.videoRef}
                                            autoPlay
                                            playsInline
                                            // onLoadedMetadata={() => console.log('Vídeo pronto para captura')}
                                            className={styles.videoFrame}
                                        />
                                    )}

                                    <canvas
                                        ref={this.canvasRef}
                                        style={{
                                            display: this.state.hasPicture ? 'block' : 'none'
                                        }}
                                        className={styles.canvaFrame}
                                    ></canvas>
                                </>
                            }

                            {this.state.questions[this.state.step]?.questionType === QuestionType.FREE_FIELD && (
                                <>
                                    <ButtonGroup className={styles.buttons} vertical={true}>
                                        {this.state.step !== 0 && !this.state.isReproving && (
                                            <Button onClick={this.onReturn}
                                                disabled={this.state.step === 0 && !this.state.isReproving}
                                                appearance="default"
                                                color="yellow">
                                                Voltar
                                            </Button>
                                        )}

                                        <div>
                                            <Input
                                                id="free_field"
                                                autoFocus
                                                className='free_field'
                                                componentClass="textarea"
                                                placeholder="Digite aqui..."
                                                rows={3}
                                                value={currentAnswer?.questionAnswer || ''}
                                                onChange={(value: string) => this.onChangeFreeFieldInput(value)}
                                            />
                                        </div>

                                        <Button 
                                            onClick={this.onAdvanceSteps}
                                            appearance="primary"
                                            color="blue"
                                            disabled={!this.state.currentFreeFieldValue || this.state.currentFreeFieldValue === ""}
                                        >
                                            Avançar
                                        </Button>
                                    </ButtonGroup>
                                </>
                            )}
                        </div>

                        {!this.state.hiddenReproveMotivation && (
                            <div className={styles.reproveMotivationContainer}>
                                <Input
                                    componentClass="textarea"
                                    rows={3}
                                    placeholder="Motivo da reprovação..."
                                    value={this.state.reprovingText}
                                    onChange={(e) => this.setState({ reprovingText: e })}
                                />
                            </div>
                        )}
                        {this.state.lastQuestionAnswered && (
                            <div className={styles.finishButtonContainer}>
                                <SignaturePad
                                    handleSetSignature={this.handleSetSignature}
                                    handleChangeSignatureModal={this.handleChangeSignatureModal}
                                />
                            </div>
                        )}
                    </div>
                </div >
                <Modal
                    show={this.state.finishModalVisible}
                    size="sm"
                    onClose={() => this.setState({ finishModalVisible: false })}>
                    <Modal.Header>
                        <Modal.Title>Finalizar Checklist</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ marginBottom: '10px' }}>
                            <label className="rs-control-label" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>Nome Completo *</label>
                            <Input
                                value={this.state.finishName}
                                onChange={(value) => this.setState({ finishName: value })}
                                placeholder="Digite o nome completo"
                            />
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <label className="rs-control-label" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>RG *</label>
                            <Input
                                value={this.state.finishRG}
                                onChange={async (value) => this.setState({ finishRG: await MethodsUtils.formatRG(value) })}
                                placeholder="Digite o RG"
                            />
                        </div>
                        <p>Você tem certeza que deseja finalizar este checklist?</p>
                        <p>Após finalizar não será possível fazer alterações.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => this.setState({ finishModalVisible: false })}
                            appearance="subtle">
                            Cancelar
                        </Button>
                        <Button
                            onClick={this.handleFinish}
                            appearance="primary"
                            color="cyan"
                            disabled={!this.state.finishName.trim() || !this.state.finishRG.trim()}>
                            Confirmar e Finalizar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <ModalOverlay
                    isVisible={this.state.modalSending}
                    message={this.state.modalSendingMessage}
                    iconClassName="fas fa-spinner fa-spin"
                />
            </>
        )
    }
}

export default AnswerQuestions;

